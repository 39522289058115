import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import { useAuthedRequest } from '~/hooks/use-authed-request';

import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';

// Create Context Object
export const BallTypeListContext = createContext<{
  ballTypeList: { label: string; value: number }[];
  ballTypeById: { [ballTypeId: string]: { label: string; value: number } };
  reload: () => void;
}>({
  ballTypeList: [],
  ballTypeById: {},
  reload: noop,
});

// Create a provider for components to consume and subscribe to changes
export const BallTypeListProvider = ({
  children,
  ballTypes,
}: PropsWithChildren<{ ballTypes: { label: string; value: number }[] }>) => {
  // const [loading, setLoading] = useState(true);
  const [ballTypeList, setBallTypes] = useState<{ label: string; value: number }[]>(ballTypes);
  const { get } = useAuthedRequest(true);

  const load = useCallback(() => {
    get(`/ball-types`, { withCredentials: false })
      .then((ballTypes) => {
        setBallTypes(
          sortBy(
            ballTypes.data.map(({ id, title }: any) => ({ label: title, value: id })),
            ['label'],
          ),
        );
        // setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [get]);

  const ballTypeById = useMemo(
    () => ballTypeList.reduce((acc, ballType) => ({ ...acc, [ballType.value]: ballType }), {}),
    [ballTypeList],
  );

  return (
    <BallTypeListContext.Provider
      value={{
        ballTypeList,
        ballTypeById,
        reload: load,
      }}
    >
      {children}
    </BallTypeListContext.Provider>
  );
};
