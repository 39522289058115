import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import { useAuthedRequest } from '~/hooks/use-authed-request';

import type { Division } from '~/types';

import { noop, sortBy } from 'lodash';

// Create Context Object
export const DivisionListContext = createContext<{
  divisionList: any[];
  divisions: Division[];
  divisionById: { [divId: string]: Division };
  reload: () => void;
}>({
  divisionList: [],
  divisions: [],
  divisionById: {},
  reload: noop,
});

// Create a provider for components to consume and subscribe to changes
export const DivisionListProvider = ({
  children,
  divisions: initDivisions,
}: PropsWithChildren<{ divisions: Division[] }>) => {
  const [divisions, setDivisions] = useState<any[]>(initDivisions);
  const { get } = useAuthedRequest(true);

  const load = useCallback(() => {
    get(`/divisions`, { withCredentials: false })
      .then((divisions) => {
        const sorted = sortBy(divisions.data, ['title']);
        setDivisions(sorted);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [get]);

  const divisionById = useMemo(() => divisions.reduce((acc, div) => ({ ...acc, [div.id]: div }), {}), [divisions]);
  const divisionList = useMemo(() => divisions.map(({ id, title }: any) => ({ label: title, value: id })), [divisions]);

  return (
    <DivisionListContext.Provider
      value={{
        divisionById,
        divisionList,
        divisions,
        reload: load,
      }}
    >
      {children}
    </DivisionListContext.Provider>
  );
};
